<template>
  <button @click="openPopup">test</button>
</template>

<script>
export default {
  name: "test",
  methods: {
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupAvatarTerms",
        isShown: true,
        props: {
          popupClass: "test",
        },
      });
    },
  },
};
</script>

<style scoped></style>
